$('.service_carousel').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    dots: false,
    arrows: true,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    nextArrow: '<div class="arrows"><i class="fa-solid fa-right-long"></i></div>',
    prevArrow: '<div class="arrows"><i class="fa-solid fa-left-long"></i></div>',
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          }
        },
    ]
});